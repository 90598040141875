import * as React from "react";
import Box from "@mui/material/Box";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { closeDialog, createGuest } from "./guestSlice";
import { useTranslation } from 'react-i18next';
import SharedLink from "./sharedLink";

export default function Guest() {
  const { t } = useTranslation();
  const open = useAppSelector((state: RootState) => state.guest.open);
  const moderatorUrl = useAppSelector(
    (state: RootState) => state.guest.moderatorUrl
  );
  const webappUrl = useAppSelector(
    (state: RootState) => state.guest.webappUrl
  );
  const wallUrl = useAppSelector(
    (state: RootState) => state.guest.wallUrl
  );
  const error = useAppSelector((state: RootState) => state.guest.error);
  const dispatch = useAppDispatch();
  const createNewGuest = () => {
    dispatch(createGuest());
  };
  const handleClose = () => {
    dispatch(closeDialog());
  };
  const links = [
    { url: moderatorUrl, label: t('guest:modal.moderation_link') },
    { url: webappUrl, label: t('guest:modal.webapp_link') },
    { url: wallUrl, label: t('guest:modal.wall_link') }
  ];

  return (
    <>
      <Box component="section" sx={{ p: 2, border: "1px dashed grey" }}>
        <Button variant="outlined" onClick={createNewGuest}>
          { t('guest:create') }
        </Button>
        <Typography variant="overline" />
        {!!error && (
          <Alert variant="outlined" severity="error" sx={{ mt: 2 }}>
            {error.message}
          </Alert>
        )}
      </Box>
      <Dialog
        style={{ minWidth: "800px" }}
        fullScreen={false}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle id="responsive-dialog-title">{ t('guest:modal.title') }</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
            { links.map((link, idx) => (<SharedLink key={idx} url={link.url} label={link.label} />))}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            { t('guest:modal.close') } 
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
