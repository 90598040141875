import * as React from "react";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Snackbar,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import LaunchIcon from "@mui/icons-material/Launch";
import { useTranslation } from 'react-i18next';

type UrlParams = {
  url: string;
  label: string;
};

export default function SharedLink({ url, label }: UrlParams) {
  const { t } = useTranslation();
  const [copied, setCopied] = React.useState(false);
  const copyLink = () => {
    setCopied(true);
    navigator.clipboard.writeText(url);
  };
  const openLink = () => {
    const win = window.open(url, "_blank");
    win?.focus();
  };
  return (
    <FormControl fullWidth margin="normal">
      <InputLabel
        variant="outlined"
        sx={{ backgroundColor: "white" }}
        htmlFor="_moderation"
      >
        {label}
      </InputLabel>
      <OutlinedInput
        type="text"
        value={url}
        endAdornment={
          <InputAdornment position="end">
            <IconButton onClick={copyLink}>
              <ContentCopyIcon />
            </IconButton>
            <Snackbar
              message={ t('guest:modal.link_copied') }
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              autoHideDuration={2000}
              onClose={() => setCopied(false)}
              open={copied}
            />
            <IconButton onClick={openLink}>
              <LaunchIcon />
            </IconButton>
          </InputAdornment>
        }
        inputProps={{
          "aria-label": "MModeration",
        }}
      />
    </FormControl>
  );
}
