import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';
import fr from './locales/fr.json';
import en from './locales/en.json';

const queryParameters = new URLSearchParams(window.location.search);
const lng = queryParameters.get('lang') || 'fr';

const langs = ['fr', 'en'];

i18n
  .use(initReactI18next)
  // .use(LanguageDetector)
  .init({
    lng,
    supportedLngs: langs,
    resources: {
      fr,
      en
    },
    // Compatibility with eurostudio:
    fallbackLng: {
      french: ['fr'],
      english: ['en'],
      default: ['fr']
    },
    ns: ['common'],
    defaultNS: 'common',
    debug: false,
    // cache: {
    //   enabled: true
    // },
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
