import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import GuestService from './guestService';

const domain = window.location.hostname || 'staging.wordclouds.app';

export interface GuestState {
  open: boolean;
  moderatorUrl: string,
  webappUrl: string,
  wallUrl: string,
  error: any;
}

const initialState: GuestState = {
  open: false,
  moderatorUrl: '',
  webappUrl: '',
  wallUrl: '',
  error: null
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const createGuest = createAsyncThunk(
  'guest/create',
  () => {
    return GuestService.createGuest();
  }
);

export const guestSlice = createSlice({
  name: 'guest',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    closeDialog: (state) => {
      state.open = false
    }
    // increment: (state) => {
    //   // Redux Toolkit allows us to write "mutating" logic in reducers. It
    //   // doesn't actually mutate the state because it uses the Immer library,
    //   // which detects changes to a "draft state" and produces a brand new
    //   // immutable state based off those changes
    //   state.value += 1;
    // },
    // decrement: (state) => {
    //   state.value -= 1;
    // },
    // // Use the PayloadAction type to declare the contents of `action.payload`
    // incrementByAmount: (state, action: PayloadAction<number>) => {
    //   state.value += action.payload;
    // },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(createGuest.pending, (state) => {
        state.moderatorUrl = '';
        state.webappUrl = '';
        state.wallUrl = '';
        state.open = false;
        state.error = null;
      })
      .addCase(createGuest.fulfilled, (state, action) => {
        state.moderatorUrl = `https://${domain}/moderation/?token=${action.payload.moderator_token}`;
        state.webappUrl = `https://${domain}/webapp/?token=${action.payload.user_token}`;
        state.wallUrl = `https://${domain}/wall/?token=${action.payload.guest_token}`;
        state.open = true;
        state.error = null;
      })
      .addCase(createGuest.rejected, (state, error) => {
        state.moderatorUrl = '';
        state.open = false;
        state.error = error.error;
      });
  },
});

export const { closeDialog } = guestSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
// export const selectCount = (state: RootState) => state.counter.value;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd =
//   (amount: number): AppThunk =>
//   (dispatch, getState) => {
//     const currentValue = selectCount(getState());
//     if (currentValue % 2 === 1) {
//       dispatch(incrementByAmount(amount));
//     }
//   };

export default guestSlice.reducer;
